.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon-hidden {
    visibility: hidden;
    pointer-events: none;
}

.content-container {
    width: 95%;
    margin: auto;
    margin-top: 30px;
}