.container {
  width: 98%;
  margin: auto;
}

.no-alerts-text {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 5rem;
}

.iconButton {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.iconButton.show {
  opacity: 1;
}