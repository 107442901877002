.container {
    display: grid;
    justify-content: end;
    padding-right: var(--spacing-16);
    width: 100%;
}

.icon-container {
    display: inline-block;
}

.icon {
    padding: 4px;
    color: var(--blue-color-primary);
}

.disabled {
    opacity: 0.5;
}