.container {
    display: flex;
    align-items: center;
    padding: 6px;
    gap: 2px;
    width: 100%;
    margin: 10px 0;
}

.input-wrapper {
    display: flex;
    align-items: center;
    gap: 2px;
    flex: 0.7;
}

.input-container {
    flex: 1;
    width: 100%;

}

.end-adornment {
    font-weight: 500;
    color: #ccc;
}