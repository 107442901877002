.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  padding: 0 0;
}

.container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}