.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.icon-container {
  flex: 0.2;
  display: flex;
  justify-content: flex-start;
}

.severity-container {
  flex: 0.8;
  display: flex;
  align-items: flex-start;
}