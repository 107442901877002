.component-ids-column {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  padding: 0 5%;
}

.align-start {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}