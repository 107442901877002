.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.error-cell {
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}
