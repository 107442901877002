.wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 1vw 1vw;
}

.header {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #4c4c4c;
}

@media only screen and (max-width: 1600px) {
  .title {
    font-size: 14px;
    font-weight: 500;
  }
}

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  transition: 0.3s;
  height: 100%;
  padding: 1em;
  border: var(--card-border);
  border-radius: 10px;
  background-color: var(--card-background-color);
  box-shadow: var(--box-shadow);
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
