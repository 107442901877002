.toast-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 20px;
    letter-spacing: 0.05em;
    text-align: center;
}

.toast-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    white-space: pre-line;
}

.toast-time-message {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
}

.success-toast {
    color: #8ee6a8;
}

.fail-toast {
    color: #ff8795;
}

.toast-btn {
    display: flex;
    justify-content: center;
    margin: 0;
}