.background-window {
  height: 100%;
  width: 100%;
  border-top-left-radius: 10px;
  background-color: #ededed;
  overflow: scroll;
  overflow-x: auto;
}

.grid-container {
  background-color: #ededed;
  height: 145%;
  margin: 1.7vw;
  display: grid;
  cursor: default;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(8, 1fr);
}

@media only screen and (max-width: 1600px) {
  .grid-container {
    height: 180%;
  }
}

.current-production {
  grid-column: 1 / 3;
  grid-row: 1 /3;
}

.commulative-production {
  grid-column: 3 / 5;
  grid-row: 1 / 3;
}

.wind {
  grid-column: 5 / 7;
  grid-row: 1 / 3;
}

.top-issues {
  grid-column: 1 / 4;
  grid-row: 3 / 6;
}

.production-irradiance {
  grid-column: 4 / 7;
  grid-row: 3 / 6;
}

.inverter-performance {
  grid-column: 1 / 4;
  grid-row: 6 / 9;
}

.tracker-states {
  grid-column: 4 / 7;
  grid-row: 6 / 9;
}
