.forecast-safety-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.forecast-threshold-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
}

.wind-gust-threshold-unit {
  font-size: 0.75rem;
}
