.container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: row;
  padding: 30px;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.soma-portal-features {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
}

.external-services-features {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
}
