.container {
  padding: 3px 6px;
  height: 100%;
}

.tab-content {
  padding: 2rem 0rem;
}

.tab {
  color: var(--blue-color-primary);
}

.tab-indicator {
  height: 5px;
  background-color: #1377e7;
  border-radius: 50px;
}

.tabs {
  margin-left: 13px;
}

.half-width {
  width: 50%;
  margin: auto;
  align-items: center;
}

.max-size {
  width: 100%;
  height: 100%;
  padding: 0;
}

.error {
  color: var(--error-color);
}