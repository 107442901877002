.container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.iconButton {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.iconButton.show {
    opacity: 1;
}

.infoIcon {
    font-size: 20px;
    color: var(--text-secondary-color);
}